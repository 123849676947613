<template>
  <div class="notifications">
    <ul v-if="notificationItems.length" class="notifications-list">
      <li
        class="notification-item"
        v-for="(item, index) in notificationItems"
        :key="index"
        :style="{
          backgroundColor: item.status ? '#FFF7ED' : '#fff',
        }"
      >
        <div class="notification-status" v-if="item.status" />
        <div class="notification-detail">
          <div class="notification-content">
            <div
              class="notification-description"
              v-html="item.message"
              :ref="`notification_${index}`"
              @mousedown="handleLinkClick($event, item.notificationId)"
            />
            <div class="notification-date">{{ formattedDate(item.createDate) }}</div>
            <div class="notification-bar" />
          </div>
        </div>
      </li>
    </ul>
    <VueText v-else sizeLevel="12" class="empty-message">
      Herhangi bir bildiriminiz bulunmamaktadır.
    </VueText>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue';

import { Notification } from '@/services/Api/index';
import VueText from '@/components/shared/VueText/VueText.vue';

export default {
  name: 'NewNotifications',
  components: { VueText },
  setup() {
    const notificationItems = ref([]);

    const padZero = number => {
      return number < 10 ? `0${number}` : number;
    };

    const formattedDate = dateTime => {
      const date = new Date(dateTime);
      return `${padZero(date.getDate())}/${padZero(
        date.getMonth() + 1,
      )}/${date.getFullYear()} · ${padZero(date.getHours())}.${padZero(date.getMinutes())}`;
    };
    const handleLinkClick = async (event, notificationId) => {
      const target = event.target;

      if (target.tagName.toLowerCase() === 'a') {
        event.preventDefault();

        const response = { notificationId: notificationId };
        const linkUrl = target.getAttribute('href');

        const res = await Notification.getViewNotification(response);
        if (res?.data?.Data) {
          window.location.href = linkUrl;
        }
      }
    };

    const getUserNotification = async () => {
      const res = await Notification.getUserNotification();
      notificationItems.value = res?.data?.Data?.userNotification ?? [];
    };

    onMounted(() => {
      getUserNotification();
    });

    return {
      notificationItems,
      formattedDate,
      handleLinkClick,
    };
  },
};
</script>

<style scoped lang="scss">
.notifications {
  .notifications-list {
    padding: 0;
    list-style-type: none;
    .notification-item {
      position: relative;
      display: block;
      width: 100%;
      border-radius: 5px;
      .notification-status {
        top: 45px;
        left: 70px;
        width: 7px;
        height: 7px;
        position: absolute;
        border-radius: 50%;
        background-color: #f1701a;
        transform: translateY(-50%);
      }

      .notification-detail {
        margin-left: 100px;
        padding: 30px 0 0 0;

        .notification-content {
          flex-direction: column;
          display: flex;
          padding-right: 10%;
          width: 100%;

          .notification-description {
            flex-grow: 1;
            color: #23303d;
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            line-height: 150%;
            font-weight: 400;
          }

          .notification-date {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            color: #3d464f;
            opacity: 0.5;
            margin-top: 5px;
          }
        }

        .notification-bar {
          background: rgba(0, 0, 0, 0.1);
          width: 100%;
          height: 1px;
          margin: 30px 0 0 0;
        }
      }
    }
  }
  .empty-message {
    text-align: center;
    margin-top: 25%;
  }
}
</style>
